import React from 'react';

export const HeroBlock = () => (
	<div className="surface-0 text-center p-4 mt-4">
		<div className="mb-3 font-bold text-4xl">
			<span className="text-900">Личный кабинет организатора</span>
		</div>
		<div className="text-700 mb-6">Один инструмент для создания мероприятия под ключ</div>
		<div className="grid">
			<div className="col-12 md:col-4 mb-4 px-5">
				<span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
					<i className="pi pi-desktop text-4xl text-blue-500"></i>
				</span>
				<div className="text-900 text-xl mb-3 font-medium">Создан для организаторов</div>
				<span className="text-700 line-height-3">
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
					fugiat nulla pariatur.
				</span>
			</div>
			<div className="col-12 md:col-4 mb-4 px-5">
				<span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
					<i className="pi pi-lock text-4xl text-blue-500"></i>
				</span>
				<div className="text-900 text-xl mb-3 font-medium">
					Хранение пользовательских данных
				</div>
				<span className="text-700 line-height-3">
					Отвечает требованиям 152 ФЗ по данным спикеров, пользователей, клиентов
					конференций
				</span>
			</div>
			<div className="col-12 md:col-4 mb-4 px-5">
				<span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
					<i className="pi pi-check-circle text-4xl text-blue-500"></i>
				</span>
				<div className="text-900 text-xl mb-3 font-medium">Простота использования</div>
				<span className="text-700 line-height-3">Интуитивно понятный интерфейс</span>
			</div>
			<div className="col-12 md:col-4 mb-4 px-5">
				<span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
					<i className="pi pi-globe text-4xl text-blue-500"></i>
				</span>
				<div className="text-900 text-xl mb-3 font-medium">Поддержка пользователей</div>
				<span className="text-700 line-height-3">
					Fermentum et sollicitudin ac orci phasellus egestas tellus rutrum tellus.
				</span>
			</div>
			<div className="col-12 md:col-4 mb-4 px-5">
				<span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
					<i className="pi pi-github text-4xl text-blue-500"></i>
				</span>
				<div className="text-900 text-xl mb-3 font-medium">Open Source</div>
				<span className="text-700 line-height-3">
					Nec tincidunt praesent semper feugiat. Sed adipiscing diam donec adipiscing
					tristique risus nec feugiat.{' '}
				</span>
			</div>
			<div className="col-12 md:col-4 md:mb-4 mb-0 px-3">
				<span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
					<i className="pi pi-shield text-4xl text-blue-500"></i>
				</span>
				<div className="text-900 text-xl mb-3 font-medium">Trusted Securitty</div>
				<span className="text-700 line-height-3">
					Mattis rhoncus urna neque viverra justo nec ultrices. Id cursus metus aliquam
					eleifend.
				</span>
			</div>
		</div>
	</div>
);
