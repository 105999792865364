import { CSSProperties, FC, ReactNode } from 'react';

import cn from 'classnames';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './DataIsGeneratedSpinner.module.scss';

import { CommonStyledComponent } from '@/src/modules/common/types/common.types';
import { useTranslations } from 'next-intl';

export type DataIsGeneratedSpinnerProps = {
	loaderClassName?: string;
	messageClassName?: string;
	message?: string | ReactNode;
	scale?: number;
	transformOrigin?: CSSProperties['transformOrigin'];
	color?: CSSProperties['color'];
	bgColor?: CSSProperties['color'];
	height?: CSSProperties['height'];
} & CommonStyledComponent;

export const DataIsGeneratedSpinner: FC<DataIsGeneratedSpinnerProps> = ({
	className,
	loaderClassName,
	messageClassName,
	message,
	scale = 3,
	transformOrigin = 'bottom center',
	color = 'var(--primary-color)',
	bgColor = 'var(--surface-section)',
	height,
}) => {
	const tCommon = useTranslations('common');

	return (
		<div
			className={cn(
				className,
				'flex flex-column justify-content-center align-items-center gap-4',
			)}
			style={{ paddingTop: `calc(${scale} * 32px)`, height }}
		>
			<span
				className={cn(styles.loader, loaderClassName)}
				style={{
					transform: `scale(${scale})`,
					transformOrigin,

					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					['--color']: color,

					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					['--bg-color']: bgColor,
				}}
			/>
			<span className={cn(messageClassName, 'opacity-70')}>
				{message ?? tCommon('data-is-generated')}
			</span>
		</div>
	);
};
