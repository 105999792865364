'use client';

import React, { FC, useEffect, useState } from 'react';

import { Login } from '@/src/modules/auth/components/Login';
import { DataIsGeneratedSpinner } from '@/src/modules/common/components/DataIsGeneratedSpinner/DataIsGeneratedSpinner';
import { HeroBlock } from '@/src/modules/common/components/screens/RootScreen/HeroBlock';
import { CreateWorkspaceButton } from '@/src/modules/srm/components/CreateWorkspaceButton';
import { useWorkspaceFromContext } from '@/src/modules/workspaces/hooks/useWorkspaceFromContext';
import { useTranslations } from 'next-intl';
import { Button } from 'primereact/button';

interface RootScreenProps {
	showPrivateContent: boolean;
}

const RootScreen: FC<RootScreenProps> = ({ showPrivateContent }) => {
	const t = useTranslations('navigation');

	const { isLoading } = useWorkspaceFromContext(showPrivateContent);
	const [rootIsLoaded, setRootIsLoaded] = useState(showPrivateContent ? false : true);

	// TODO temporary off, think obout it
	// useEffect(() => {
	// 	if (workspace?.slug ?? workspace?.id) {
	// 		setTimeout(() => {
	// 			router.push(
	// 				urlWithId(routes[Routes.Dashboard].path, routesParts.workspace, workspace?.slug ?? workspace?.id),
	// 			);
	// 		}, 500);
	// 	}
	// }, [router, workspace]);

	useEffect(() => {
		if (isLoading === false) {
			setTimeout(() => setRootIsLoaded(true), 300);
		}
	}, [isLoading]);

	return (
		<>
			<HeroBlock />
			<div className="surface-0 text-700 text-center mt-4">
				<div className="text-900 font-bold text-4xl mb-3">Присоединяйся к нам</div>
				<div className="text-700 text-2xl mb-5">
					Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit numquam eligendi
					quos.
				</div>
				{!rootIsLoaded && (
					<div className="mt-8">
						<DataIsGeneratedSpinner />
					</div>
				)}
				{!showPrivateContent && (
					<Button
						icon="pi pi-user mr-2"
						className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
					>
						<Login linkName={t('items.login')} />
					</Button>
				)}
				{showPrivateContent && rootIsLoaded && (
					<div className="mt-8">
						<CreateWorkspaceButton mode="link" />
					</div>
				)}
			</div>
		</>
	);
};

export default RootScreen;
